var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoad,
          expression: "isLoad",
        },
      ],
      staticClass: "fromProcess-relav-form",
    },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
            scopedSlots: _vm._u([
              {
                key: "saveBeforeSlot",
                fn: function () {
                  return undefined
                },
                proxy: true,
              },
              {
                key: "footerSlot",
                fn: function () {
                  return [
                    _vm.editable && !_vm.isPass
                      ? _c("v-button", {
                          attrs: { text: "保存", type: "success" },
                          on: { click: _vm.save },
                        })
                      : _vm._e(),
                    _vm.form.status == 0 && !_vm.editable && !_vm.isPass
                      ? _c("v-button", {
                          attrs: { text: "编辑", type: "success" },
                          on: { click: _vm.editPage },
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基础信息" } },
                [
                  this.form.id !== undefined
                    ? _c("el-form-item", { attrs: { label: "单据单号" } }, [
                        _c("div", {
                          domProps: { textContent: _vm._s(_vm.form.id) },
                        }),
                      ])
                    : _vm._e(),
                  this.form.id !== undefined
                    ? _c("el-form-item", { attrs: { label: "单据类型" } }, [
                        _c("div", {
                          domProps: { textContent: _vm._s(_vm.form.formType) },
                        }),
                      ])
                    : _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "单据类型",
                            rules: [
                              {
                                required: true,
                                message: "请选择单据类型",
                                trigger: "change",
                              },
                            ],
                            prop: "formType",
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                attrs: {
                                  placeholder: "单据类型",
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.formType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "formType", $$v)
                                  },
                                  expression: "form.formType",
                                },
                              },
                              "v-select2",
                              _vm.billTypeParams,
                              false
                            )
                          ),
                        ],
                        1
                      ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "请假原因",
                        rules: [
                          {
                            required: true,
                            message: "请输入请假原因",
                            trigger: "change",
                          },
                        ],
                        prop: "reason",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入请假原因",
                          disabled: !_vm.editable,
                        },
                        model: {
                          value: _vm.form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "reason", $$v)
                          },
                          expression: "form.reason",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "请假时间",
                        rules: _vm.timeRules,
                        prop: "leaveDateRange",
                      },
                    },
                    [
                      _c("v-datepicker", {
                        attrs: {
                          startTime: _vm.form.leaveDateRange.startOffDate,
                          endTime: _vm.form.leaveDateRange.endOffDate,
                          type: "rangedate",
                          disabled: !_vm.editable,
                        },
                        on: {
                          schange: _vm.onStartDateChange,
                          echange: _vm.onEndDateChange,
                          "update:startTime": function ($event) {
                            return _vm.$set(
                              _vm.form.leaveDateRange,
                              "startOffDate",
                              $event
                            )
                          },
                          "update:start-time": function ($event) {
                            return _vm.$set(
                              _vm.form.leaveDateRange,
                              "startOffDate",
                              $event
                            )
                          },
                          "update:endTime": function ($event) {
                            return _vm.$set(
                              _vm.form.leaveDateRange,
                              "endOffDate",
                              $event
                            )
                          },
                          "update:end-time": function ($event) {
                            return _vm.$set(
                              _vm.form.leaveDateRange,
                              "endOffDate",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "请假天数",
                        rules: [
                          {
                            required: true,
                            message: "请输入请假天数",
                            trigger: "change",
                          },
                        ],
                        prop: "daysOff",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入请假天数",
                          width: _vm.width,
                          disabled: true,
                        },
                        model: {
                          value: _vm.form.daysOff,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "daysOff", $$v)
                          },
                          expression: "form.daysOff",
                        },
                      }),
                    ],
                    1
                  ),
                  this.form.applyer
                    ? _c("el-form-item", { attrs: { label: "发起人" } }, [
                        _c("div", {
                          domProps: { textContent: _vm._s(_vm.form.applyer) },
                        }),
                      ])
                    : _vm._e(),
                  this.form.currTaskName
                    ? _c("el-form-item", { attrs: { label: "当前任务名称" } }, [
                        _c("div", {
                          domProps: {
                            textContent: _vm._s(_vm.form.currTaskName),
                          },
                        }),
                      ])
                    : _vm._e(),
                  this.form.processName
                    ? _c("el-form-item", { attrs: { label: "当前流程名称" } }, [
                        _c("div", {
                          domProps: {
                            textContent: _vm._s(_vm.form.processName),
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm.isPass && _vm.isPassConfirm
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "审批意见", prop: "comment" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              placeholder: "请输入审批意见",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.comment,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "comment", $$v)
                              },
                              expression: "form.comment",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isCancel
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "撤回意见", prop: "cancelReason" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              placeholder: "请输入撤回意见",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.cancelReason,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cancelReason", $$v)
                              },
                              expression: "form.cancelReason",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }