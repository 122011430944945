// const baseURL = 'http://10.12.41.17:8080'
const baseURL = 'http://172.16.50.22:7020/manage/'
// 获取列表接口
const getLeaveFormListURL = `${API_CONFIG.butlerBaseURL}leave2/list`
// 查询数据接口
const queryURL = `${API_CONFIG.butlerBaseURL}leave2/queryById`
// 提交数据接口
const updateURL = `${API_CONFIG.butlerBaseURL}leave2/edit`
// 新增数据接口
const createURL = `${API_CONFIG.butlerBaseURL}leave2/add`
// 删除数据接口
const deleteURL = `${API_CONFIG.butlerBaseURL}leave2/delete`
// 删除数据接口
const batchDeleteURL = `${API_CONFIG.butlerBaseURL}leave2/deleteBatch`
// 提交流程接口
const applyFormURL = `${API_CONFIG.butlerBaseURL}leave2/formApply`
// 通过审核
const passFormURL = `${API_CONFIG.butlerBaseURL}leave2/completeTask`
// 驳回至发起人
const backFormURL = `${API_CONFIG.butlerBaseURL}leave2/backProcess`
// 撤回申请
const cancelFormURL = `${API_CONFIG.butlerBaseURL}leave2/cancel`
// 获取审批历史
const getHistoryFlowListURL = `${API_CONFIG.butlerBaseURL}actTask/historicFlow`
// 获取单据类型列表
const getBilltypeListURL = `${API_CONFIG.butlerBaseURL}activiti/billtype/getBilltypeSelect2`

export {
    getLeaveFormListURL,
    queryURL,
    updateURL,
    createURL,
    deleteURL,
    batchDeleteURL,
    applyFormURL,
    passFormURL,
    backFormURL,
    cancelFormURL,
    getHistoryFlowListURL,
    getBilltypeListURL
}