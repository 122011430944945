<template>
  <div class="fromProcess-relav-form" v-show="isLoad">
    <form-panel
      ref="formPanel"
      :form="form"
      v-bind="submitConfig"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block title="基础信息">

          <el-form-item v-if="this.form.id !== undefined" label="单据单号">
            <div v-text="form.id"></div>
          </el-form-item>

          <el-form-item v-if="this.form.id !== undefined" label="单据类型">
            <div v-text="form.formType"></div>
          </el-form-item>
          <el-form-item v-else label="单据类型" :rules="[{ required: true, message: '请选择单据类型', trigger: 'change' }]" prop="formType">
            <v-select2 
                v-model="form.formType"
                placeholder="单据类型"
                v-bind="billTypeParams"
                :width="width" />
          </el-form-item>

          <el-form-item label="请假原因" :rules="[{ required: true, message: '请输入请假原因', trigger: 'change' }]" prop=reason>
            <v-input v-model="form.reason" placeholder="请输入请假原因" :disabled="!editable" />
          </el-form-item>

          <el-form-item label="请假时间" :rules="timeRules" prop="leaveDateRange">
            <v-datepicker 
                @schange="onStartDateChange" 
                @echange="onEndDateChange" 
                :startTime.sync="form.leaveDateRange.startOffDate" 
                :endTime.sync="form.leaveDateRange.endOffDate" 
                type="rangedate"
                :disabled="!editable">
            </v-datepicker>
          </el-form-item>

          <el-form-item label="请假天数" :rules="[{ required: true, message: '请输入请假天数', trigger: 'change' }]" prop="daysOff">
            <v-input v-model="form.daysOff" placeholder="请输入请假天数" :width="width" :disabled="true" />
          </el-form-item>

          <el-form-item v-if="this.form.applyer" label="发起人">
            <div v-text="form.applyer"></div>
          </el-form-item>
          <el-form-item v-if="this.form.currTaskName" label="当前任务名称">
            <div v-text="form.currTaskName"></div>
          </el-form-item>
          <el-form-item v-if="this.form.processName" label="当前流程名称">
            <div v-text="form.processName"></div>
          </el-form-item>

          <el-form-item v-if="isPass && isPassConfirm" label="审批意见" prop="comment">
            <v-textarea v-model="form.comment" placeholder="请输入审批意见" :width="width" />
          </el-form-item>
          <el-form-item v-if="isCancel" label="撤回意见" prop="cancelReason">
            <v-textarea v-model="form.cancelReason" placeholder="请输入撤回意见" :width="width" />
          </el-form-item>

        </col2-block>
      </col2-detail>
      <template #saveBeforeSlot>
      </template>
      <template #footerSlot>
        <v-button  text="保存"   type="success" @click="save" v-if="editable && !isPass"/>
        <v-button  text="编辑"   type="success" @click="editPage" v-if="form.status==0 && !editable && !isPass"/>
      </template>
    </form-panel>
  </div>
</template>

<script>
import { 
  queryURL,
  createURL,
  updateURL,
  applyFormURL,
  passFormURL,
  backFormURL,
  cancelFormURL,
  getBilltypeListURL } from './api'
import { Col2Block, Col2Detail } from '@/components/bussiness'
import moment from 'moment'

export default {
  name: 'LeaveForm',
  components: {
    Col2Block,
    Col2Detail
  },
  data () {

    return {
      width: 200,
      buttonWidth: 20,
      isLoad: false,
      submitConfig: {
        queryUrl: queryURL,
        submitUrl: undefined,
        submitMethod: ''
      },
      disabled: false,
      editable: true,
      form: {
        id: undefined,
        billtypeId: '',
        reason: '',
        daysOff: 7,
        leaveDateRange: {
            startOffDate: moment().add(1,'days').format('YYYY-MM-DD'),
            endOffDate: moment().add(7,'days').format('YYYY-MM-DD')
        },
        comment:'',
        cancelReason:''
      },
      billTypeParams : {
        searchUrl: getBilltypeListURL,
        request: {
          text: 'name',
          value: 'id'
        },
        response: {
          text: 'billtypeName',
          value: 'billtypeName'
        },
      },
      timeRules: [
        {
          validator: (rule, value, callback) => {
            if (value) {
              let { startOffDate, endOffDate } = value
              if (!startOffDate) {
                callback(new Error(`请选择开始时间`))
              } else if (!endOffDate) {
                callback(new Error(`请选择结束时间`))
              } else {
                callback()
              }
            } else {
              callback(new Error(`请选择请假日期`))
            }
          },
          trigger: 'blur'
        },
        {
          required: true
        }
      ],
      formData: {},
      isPass: false,
      isPassConfirm: false,
      canclable: false,
      isCancel: false
    }
  },
  created () {
    
  },
  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.formData = this.$route.query
      this.disabled = true
      this.editable = false
      this.$setBreadcrumb('编辑')
      this.submitConfig.submitUrl = updateURL
      this.submitConfig.submitMethod = 'POST'
      let userId = this.$store.state.userInfo.id
      this.$refs.formPanel.getData({
        id,userId
      })
    } else {
      this.$setBreadcrumb('新增')
      this.submitConfig.submitUrl = createURL
      this.submitConfig.submitMethod = 'POST'
    }
    this.isLoad = true
  },
  methods: {
    onStartDateChange (value) {
        console.log(value)
        let daysOff = moment(this.form.leaveDateRange.endOffDate).diff(moment(value),'day') + 1
        this.form.daysOff = daysOff
    },
    onEndDateChange (value) {
        console.log(value)
        let daysOff = moment(value).diff(moment(this.form.leaveDateRange.startOffDate),'day') + 1
        this.form.daysOff = daysOff
    },
    editPage () {
        this.editable = !this.editable
    },
    showHistory () {
      this.$router.push({
        name: 'historyFlowList',
        query: {
          procInstId: this.form.procInstId
        }
      })
    },
    save () {
      this.form.startOffDate = this.form.leaveDateRange.startOffDate
      this.form.endOffDate = this.form.leaveDateRange.endOffDate
      this.form.userId = this.$store.state.userInfo.id
      this.$refs.formPanel.submitClick()
    },
    handleCancel () {
      this.isCancel = true
    },
    cancelConfirm () {
      this.isCancel = false
    },
    cancleSubmit () {
      console.log('点击了撤回')
      let params = {
        id : this.form.id,
        procInstId: this.form.procInstId,
        reason: this.form.cancelReason
      }
      this.$axios.get(cancelFormURL, {params : params}).then(res=>{
        if(res.status === 100) {
          this.$message.success("撤回成功")
          this.$router.go(-1)
        }
      })
    },
    publish () {
      // this.submitConfig.submitUrl = applyFormURL
      // this.$refs.formPanel.submitClick()
      let params = {
        id: this.formData.id
      }
      this.$axios.post(applyFormURL, null, {params:params}).then(res=>{
        console.log(res)
        if(res.status == 100) {
          this.$message.success("提交申请成功")
          this.$router.go(-1)
        }
      })
    },
    handlePass () {
      this.isPassConfirm = true
    },
    handlePassCancel () {
      this.isPassConfirm = false
    },
    passClick () {
      // this.submitConfig.submitUrl = passFormURL
      let params = {
        taskId : this.form.taskId,
        procInstId: this.form.procInstId,
        userId: this.$store.state.userInfo.id,
        comment : this.form.comment
      }
      this.$axios.get(passFormURL, {params : params}).then(res=>{
        if(res.status === 100) {
          this.$message.success("审核操作成功")
          this.$router.go(-1)
        }
      })
    },
    backClick() {
      console.log("点击了驳回")
      let params = {
        id : this.form.taskId,
        procInstId: this.form.procInstId,
        comment : this.form.comment
      }
      this.$axios.post(backFormURL, null,{params : params}).then(res=>{
        if(res.status === 100) {
          this.$message.success("审核操作成功")
          this.$router.go(-1)
        }
      })
    },
    update (data) {
      this.formData = data
      // this.isPass = data.taskId ? true : false
      this.form = Object.assign(this.form,data)
      this.form.leaveDateRange.startOffDate = data.startOffDate
      this.form.leaveDateRange.endOffDate = data.endOffDate
      // this.canclable = data.userId==this.$store.state.userInfo.id ? true : false
    },
    submitBefore (data) {
      console.log(data)
      // data = Object.assign( this.formData, data )
      return data
    }
  }
}
</script>

<style lang="scss">
.sensitive-word-form {
  .el-form-item__error {
    white-space: nowrap;
  }
}
</style>
<style lang="scss" scoped>
.sensitive-word-form {
  .tips {
    margin-left: 20px;
  }
}
</style>
